
import { isEmpty } from "lodash";
import { LangCode, PrestonUser } from "@prestonly/preston-common";
import Vue from "vue";
import { Route } from "vue-router";
import { codeToFlagMixin } from "@/utils/codeToFlagMixin";
import { getAvailableLanguages, LangConfig } from "@/utils/languages";

export default Vue.extend({
  name: "HeaderLanguageSwitcher",
  mixins: [codeToFlagMixin],
  components: {},
  data() {
    return {};
  },
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    userLoaded(): boolean {
      return this.user && !isEmpty(this.user);
    },

    trialDaysLeft(): number {
      return this.$store.getters["user/getTrialDaysLeft"];
    },

    languages(): LangConfig[] {
      const userLanguages = this.userLoaded ? this.user.availableLanguages.map(({ targetLang }) => targetLang) : [];
      const isTrial = this.trialDaysLeft >= 0;
      return getAvailableLanguages().map((langConfig) => {
        return {
          ...langConfig,
          hasAccess: isTrial ? true : userLanguages.includes(langConfig.langCode),
        };
      });
    },
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    currentLang(): LangConfig {
      return this.languages.find((lang) => lang.langCode === this.learnLanguage) || this.languages[0];
    },
  },
  methods: {
    setLearnLang(lang: LangConfig): Promise<Route | void> | void {
      if (this.learnLanguage === lang.langCode) {
        return;
      }
      this.$store.dispatch("prestonState/setLearnLanguage", lang.langCode).then((lang) => {
        if (this.$route.name?.includes("subscribe")) {
          return;
        }
        if (this.$route.name !== "courses") {
          this.$router.push({ name: "root", params: { lang: lang.code } });
        }
      });
      return;
    },
  },
});
