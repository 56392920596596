
import Vue, { VueConstructor } from "vue";
import { SUBSCRIPTION_WARNING_DAYS } from "@/constants/subscriptionBar";
import { LangCode, PrestonUser } from "@prestonly/preston-common";
import { isEmpty } from "lodash";
import { SubscriptionState } from "@/store/modules/user";
import { startTrialMixin } from "@/utils/startTrialMixin";

export default (Vue as VueConstructor<Vue & InstanceType<typeof startTrialMixin>>).extend({
  name: "HeaderSubscriptionStatusBar",
  mixins: [startTrialMixin],
  computed: {
    langCode(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },

    trialDaysLeft(): number {
      return this.$store.getters["user/getTrialDaysLeft"];
    },

    subscriptionDaysLeft(): number {
      return this.$store.getters["user/getSubscriptionDaysLeft"](this.langCode);
    },

    isTrialAvailable(): number {
      return this.$store.getters["user/isTrialAvailable"];
    },

    state(): SubscriptionState {
      const isTrialActive = this.trialDaysLeft >= 0;
      const isTrialOverToday = this.trialDaysLeft === 0;
      const isSubscriptionActive = this.subscriptionDaysLeft >= 0;
      const isSubscriptionOverToday = this.subscriptionDaysLeft === 0;
      const isSubscriptionOverSoon = isSubscriptionActive && this.subscriptionDaysLeft <= SUBSCRIPTION_WARNING_DAYS;

      if (this.isTrialAvailable && !isSubscriptionActive) {
        return SubscriptionState.NoSubAvailableTrial;
      }
      if (!isTrialActive && !isSubscriptionActive) {
        return SubscriptionState.NoSubNoTrial;
      }
      if (!isSubscriptionActive && isTrialOverToday) {
        return SubscriptionState.TrialLastDay;
      }
      if (!isSubscriptionActive && isTrialActive) {
        return SubscriptionState.TrialOverSoon;
      }
      if (isSubscriptionOverToday) {
        return SubscriptionState.SubLastDay;
      }
      if (isSubscriptionOverSoon) {
        return SubscriptionState.SubOverSoon;
      }
      return SubscriptionState.AllNormal;
    },
    textContent(): string {
      switch (this.state) {
        case SubscriptionState.NoSubAvailableTrial:
          return "header.statusBar.noSubAvailableTrial";
        case SubscriptionState.NoSubNoTrial:
          return "header.statusBar.noSubNoTrial";
        case SubscriptionState.TrialOverSoon:
          return "header.statusBar.trialOverSoon";
        case SubscriptionState.TrialLastDay:
          return "header.statusBar.trialLastDay";
        case SubscriptionState.SubOverSoon:
          return "header.statusBar.subOverSoon";
        case SubscriptionState.SubLastDay:
          return "header.statusBar.subLastDay";
        case SubscriptionState.AllNormal:
        default:
          return "";
      }
    },
    buttonContent(): string {
      switch (this.state) {
        case SubscriptionState.NoSubAvailableTrial:
          return "header.statusBar.startTrial";
        case SubscriptionState.NoSubNoTrial:
        case SubscriptionState.TrialOverSoon:
        case SubscriptionState.TrialLastDay:
          return "header.statusBar.subscriptionPlans";
        case SubscriptionState.SubOverSoon:
        case SubscriptionState.SubLastDay:
          return "header.statusBar.extendSubscription";
        case SubscriptionState.AllNormal:
        default:
          return "";
      }
    },
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    isBtnVisible(): boolean {
      return !["subscribeSummary", "subscribe"].includes(this.$route.name || "");
    },
    isVisible(): boolean {
      if (isEmpty(this.user)) {
        return false;
      }
      if (
        /admin/.test(this.$route.name || "") ||
        ["selectLanguage", "userOnboarding", "levelingTest"].includes(this.$route.name || "")
      ) {
        return false;
      }
      if (
        this.$route.name === "subscribe" &&
        [SubscriptionState.TrialOverSoon, SubscriptionState.TrialLastDay].includes(this.state)
      ) {
        return true;
      }
      return this.state !== SubscriptionState.AllNormal;
    },
    days(): number {
      switch (this.state) {
        case SubscriptionState.TrialOverSoon:
          return this.trialDaysLeft;
        case SubscriptionState.SubOverSoon:
          return this.subscriptionDaysLeft;
        default:
          return -1;
      }
    },
  },
  methods: {
    async action() {
      if (this.state === SubscriptionState.NoSubAvailableTrial) {
        await this.startTrial();
        return;
      }
      await this.$router.push({ name: "subscribe" });
    },
  },
});
