
import { AdminSection } from "@/types/admin";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "HeaderDesktop",

  props: {
    links: {
      type: Array as PropType<AdminSection[]>,
      default: () => [],
      required: true,
    },
    showLanguageSwitcher: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isBackBtnVisible(): boolean {
      return ["lessonModeSelector", "repetitionModeSelector", "courseDetails"].includes(this.$route.name || "");
    },
    backBtnText(): string | TranslateResult {
      switch (this.$route.name) {
        case "lessonModeSelector":
          return this.$t("backToCourse");
        case "courseDetails":
          return this.$t("backToCoursesBtn");
        case "repetitionModeSelector":
          return this.$t("backToExercises");
        default:
          return "";
      }
    },
  },

  methods: {
    backTo() {
      switch (this.$route.name) {
        case "lessonModeSelector":
          return this.$router.push({ name: "courseDetails", params: { courseId: this.$route.params.courseId } });
        case "courseDetails":
          return this.$router.push({ name: "courses" });
        case "repetitionModeSelector":
          return this.$router.push({ name: "exercises" });
        default:
          return;
      }
    },
  },
});
