
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import Btn from "@/components/v2/base/Btn.vue";
import { AdminSection } from "@/types/admin";

export default Vue.extend({
  name: "HeaderMobile",

  components: {
    Btn,
  },

  data() {
    return {
      drawer: false,
      group: null,
    };
  },

  props: {
    links: {
      type: Array as PropType<AdminSection[]>,
      default: () => [],
      required: true,
    },
    showLanguageSwitcher: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isBackBtnVisible(): boolean {
      return ["lessonModeSelector", "repetitionModeSelector", "courseDetails"].includes(this.$route.name || "");
    },
  },

  methods: {
    backTo() {
      switch (this.$route.name) {
        case "lessonModeSelector":
          return this.$router.push({ name: "courseDetails", params: { courseId: this.$route.params.courseId } });
        case "courseDetails":
          return this.$router.push({ name: "courses" });
        case "repetitionModeSelector":
          return this.$router.push({ name: "exercises" });
        default:
          return;
      }
    },
  },
});
