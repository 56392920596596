
import Vue, { PropType } from "vue";

import HeaderUserMenu from "@/components/base/HeaderUserMenu.vue";
import HeaderLanguageSwitcher from "@/components/base/HeaderLanguageSwitcher.vue";
import HeaderSubscriptionStatusBar from "@/components/base/HeaderSubscriptionStatusBar.vue";
import HeaderDesktop from "@/components/base/HeaderDesktop.vue";
import HeaderMobile from "@/components/base/HeaderMobile.vue";
import { AdminSection } from "@/types/admin";

export default Vue.extend({
  name: "Header",
  components: {
    HeaderDesktop,
    HeaderMobile,
    HeaderUserMenu,
    HeaderLanguageSwitcher,
    HeaderSubscriptionStatusBar,
  },

  props: {
    links: {
      type: Array as PropType<AdminSection[]>,
      default: () => [],
      required: true,
    },
    showLanguageSwitcher: {
      type: Boolean,
      default: false,
    },
  },
});
